import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from "gatsby-plugin-intl-v4"
import { StaticImage } from "gatsby-plugin-image"
import Nav from "../components/Nav"
import Hammer from "react-hammerjs"
import FullPageLogo from "../components/FullPageLogo"
import FullPageMenu from "../components/FullPageMenu"
import FrontpageLink from "../components/FrontpageLink"
import { GlobalHead } from "../components/GlobalHead"
import "../styles.scss"
import $ from "jquery"


class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.loadedAt = (new Date()).getTime()
    this.isShowing = false
    this.isHiding = false

    this.state = { shuffledImages: this.props.data.allContentfulFrontPageImage.nodes };

    this.update = (e => {
      this.updateNavbarVisibility()
      this.updateLogoOffset('nav-logo', 'main-logo', 'nav-logo-wrapper-inner')
      this.updateItemOffset('nav-item-urban', 'menu-item-urban', 'nav-item-urban', -32)
      this.updateItemOffset('nav-item-sacral', 'menu-item-sacral', 'nav-item-sacral', -32)
      this.updateItemOffset('nav-item-housing', 'menu-item-housing', 'nav-item-housing', -32)
    })

    this.hintScroll = (e => {
      if ($(window).scrollTop() < 30 && (new Date()).getTime() - this.loadedAt > 300) {
        $('html, body').animate({
            scrollTop: 100
        }, 200)
      }
    })
  }

  updateNavbarVisibility() {

    var y = $(window).scrollTop()

    if (y > 0 && !this.isShowing) {

      this.isShowing = true
      this.isHiding = false

      $('nav')
        .clearQueue()
        .stop()
        .animate({opacity: 1.0}, 200, () => { this.isShowing = false })
    } else if (y == 0 && !this.isHiding) {

      this.isShowing = false
      this.isHiding = true

      $('nav')
        .clearQueue()
        .stop()
        .animate({opacity: 0.0}, 200, () => { this.isHiding = false })
    }
  }

  updateItemOffset(item, trackedItem, navWrapper, offset) {
    var y = $(window).scrollTop()

    var itemHeight = $('.' + item).height()
    var trackedItemHeight = $('.' + trackedItem).height()

    var windowWidth = $(window).width()
    var itemOffset = ((($('.' + trackedItem).offset().top - y + 0.12 * windowWidth)) * (itemHeight) / (trackedItemHeight))

    itemOffset = Math.max(itemOffset + offset, 0)

    $('.' + navWrapper).css('top', itemOffset + 'px')
  }

  updateLogoOffset(item, trackedItem, navWrapper) {

    var y = $(window).scrollTop()

    var navLogoHeight = $('.' + item).height()
    var mainLogoHeight = $('.' + trackedItem).height()

    var windowWidth = $(window).width()
    var mainLogoOffset = ($('.' + trackedItem).offset().top - y + 0.12 * windowWidth) * navLogoHeight / mainLogoHeight

    mainLogoOffset = Math.max(mainLogoOffset, 0)

    $('.' + navWrapper).css('top', mainLogoOffset + 'px')
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.update)
      document.addEventListener('touchmove', this.update)
      window.addEventListener('resize', this.update)
    }
    this.update()
    this.shuffleImages()
  }

  shuffleImages() {
    this.setState({
      shuffledImages: this.state.shuffledImages.sort(() => 0.5 - Math.random())
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.update)
    document.removeEventListener('touchmove', this.update)
    window.removeEventListener('resize', this.update)
  }

  render() {
    return (
      <div>
        <Nav style={{opacity: '0.0'}} />
        <Hammer onTap={this.hintScroll}><div><FullPageLogo /></div></Hammer>
        <FullPageMenu/>
        <main>
          <div style={{marginLeft: '32px', marginRight: '32px'}}>
            <div className={'ig-grid'}>
              {this.state.shuffledImages.map((node) =>
                <FrontpageLink key={node.image.url} to={node.link} children={<img src={node.image.url + '?w=800&h=800&fm=jpg&q=45'} />} />
              )}
            </div>
          </div>
        </main>
      </div>
    )
  }
}

export const query = graphql`
  query {
    allContentfulFrontPageImage(filter: {node_locale: {eq: "en-US"}}) {
      nodes {
        image {
          url
        }
        link
      }
    }
  }
`;

export function Head() {
  return (
    <GlobalHead title="NOAN" />
  )
}

export default IndexPage
