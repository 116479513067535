import React, { Component } from 'react'

const FullPageLogo = () => {
  return (
    <div className={'container'}>
      <h1 className={'logo notranslate'}>
        <span className={'noan main-logo'}>
          NOA
          <span className={'last-letter'}>N</span>
        </span>
        <p className={'studio'}>
          architecture<span style={{fontSize: 0, letterSpacing: 0}}> </span>studi
          <span className={'last-letter'}>o</span>
        </p>
      </h1>
    </div>
  )
}

export default FullPageLogo
