import React, { Component } from 'react'
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl-v4"


const FullPageLogo = () => {
  const intl = useIntl()
  return (
    <div className={'fullpage-menu'}>
      <Link to={ '/urban' }>
        <h1 className={'menu-item notranslate'}>
          <span className={'menu-item-urban'}>
            {intl.formatMessage({ id: "urbanMenu" })}
          </span>
        </h1>
      </Link>
      <Link to={ '/public' }>
        <h1 className={'menu-item notranslate'}>
          <span className={'menu-item-sacral'}>
            {intl.formatMessage({ id: "publicMenu" })}
          </span>
        </h1>
      </Link>
      <Link to={ '/housing' }>
        <h1 className={'menu-item notranslate'}>
          <span className={'menu-item-housing'}>
            {intl.formatMessage({ id: "housingMenu" })}
          </span>
        </h1>
      </Link>
    </div>
  )
}

export default FullPageLogo
