import React, { Component } from 'react'
import { Link } from "gatsby-plugin-intl-v4"

const FrontpageLink = ({ children, to }) => {
  const internal = /^\/(?!\/)/.test(to)

  if (to == null) {
    return (
      <>
        {children}
      </>
    )
  }
  if (internal) {
    return (
      <Link to={to}>
        {children}
      </Link>
    )
  }
  return (
    <a href={to}>
      {children}
    </a>
  )
}

export default FrontpageLink
